class Discount {
    id: number = 0;
    discountCode: string = '';
    validFrom: string | null = null;
    validTo: string | null = null;
    singleUse: boolean = false;
    dateUsed: string | null = null;
    setupDiscountGbp: number = 0;
    routerDiscountGbp: number = 0;
    monthlyDiscountGbp: number = 0;
    monthlyDiscountDuration: number | null = null;
    isActive: boolean = false;
    validTariffs: string = '';
    validLocalPops: string = '';
    minimumTerm: number | null = null;
    description: string = '';
    isPublic: boolean = false;
    setupDiscountPercentage : number = 0;
    routerDiscountPercentage : number = 0;
    monthlyDiscountPercentage : number = 0;
    usePercentages: boolean = false;
}

export default Discount;